{
  "name": "web-app",
  "version": "3.202.0",
  "license": "MIT",
  "scripts": {
    "ng": "ng",
    "start": "concurrently \"ng serve\" \"npm run lint\"",
    "start-ssl": "concurrently \"ng serve --ssl true --ssl-cert \"src/ssl/server.crt\" --ssl-key \"src/ssl/server.key\"\" \"npm run lint\"",
    "start-no-host-check": "ng serve --host 0.0.0.0 --disable-host-check",
    "start-no-host-check-ssl": "ng serve --host 0.0.0.0 --disable-host-check --ssl true --ssl-cert \"src/ssl/server.crt\" --ssl-key \"src/ssl/server.key\"",
    "build": "ng build",
    "style-lint": "npx stylelint \"**/*.less\"",
    "lint": "concurrently \"npx eslint\" \"npm run style-lint\"",
    "format": "npx prettier . --write",
    "analyze:circular": "npx madge --circular --extensions ts ./",
    "test": "playwright test -c functional-tests/playwright.config.ts"
  },
  "private": true,
  "dependencies": {
    "@adyen/adyen-web": "~6.9.0",
    "@angular/animations": "^19.0.5",
    "@angular/cdk": "~19.0.4",
    "@angular/common": "^19.0.5",
    "@angular/core": "^19.0.5",
    "@angular/forms": "^19.0.5",
    "@angular/language-service": "^19.0.5",
    "@angular/material": "~19.0.4",
    "@angular/platform-browser": "^19.0.5",
    "@angular/platform-browser-dynamic": "^19.0.5",
    "@angular/router": "^19.0.5",
    "@angular/service-worker": "^19.0.5",
    "@growthbook/growthbook": "^0.26.0",
    "@ngx-translate/core": "^15",
    "@sentry/angular": "^8.7.0",
    "@typeform/embed": "^4.10.0",
    "croppie": "^2.6.5",
    "date-fns": "^4",
    "firebase": "^11.2.0",
    "heic2any": "^0.0.4",
    "mapbox-gl": "^3",
    "ngx-date-fns": "^12.0.0",
    "ngx-infinite-scroll": "^19",
    "ngx-mask": "^19",
    "rxjs": "^6.6.7",
    "smoothscroll-polyfill": "^0.4.4",
    "swiper": "^8.4.7",
    "tslib": "^2.5.0",
    "vm": "^0.1.0",
    "web-animations-js": "^2.3.2",
    "zone.js": "^0.15.0"
  },
  "devDependencies": {
    "@angular/build": "^19.1.3",
    "@angular/cli": "^19.0.6",
    "@angular/compiler": "^19.0.5",
    "@angular/compiler-cli": "^19.0.5",
    "@carlosjeurissen/stylelint-csstree-validator": "^3",
    "@playwright/test": "^1.49.1",
    "@types/croppie": "^2.6.1",
    "@types/digital-goods-browser": "^2.0.3",
    "@types/facebook-js-sdk": "^3.3.11",
    "@types/google.accounts": "^0.0.14",
    "@types/node": "^20",
    "@types/rx": "^4.1.2",
    "@types/smoothscroll-polyfill": "^0.3.1",
    "axios": "^1.6.8",
    "concurrently": "^9",
    "dotenv": "^16.4.5",
    "esbuild-visualizer": "^0.7.0",
    "eslint": "^9",
    "isomorphic-fetch": "^3.0.0",
    "less": "^4.2.0",
    "madge": "^8",
    "playwright": "^1.49.1",
    "postcss-less": "^6.0.0",
    "prettier": "3.3.3",
    "set-cookie-parser": "^2.6.0",
    "stylelint": "^16",
    "stylelint-config-standard-less": "^3",
    "ts-node": "^10",
    "typescript": "~5.7",
    "typescript-eslint": "^8"
  }
}